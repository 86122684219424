import { FilterType, OperatorType } from 'utils/filterUtils';
import moment from 'moment';
import { BreakoutShowHideOptions, DateGroup, GroupBy, Months, SummaryFieldNames, FormulaType, CommonTimeFormat } from './Constants';
import { constants } from 'buffer';
import { isEmpty } from 'lodash';

export const filterData = (string, data, properties) => {
    if (string.length > 0) {
        const newRows = data.filter((row) => {
            return properties.some((property) => {
                if (row[property].toString().toLowerCase().includes(string.toString().toLowerCase())) {
                    return true;
                }
                return false;
            });
        });

        return newRows;
    }

    return data;
};

export const filterSearch = (newString, templates) => {
    return filterData(newString, templates, ['templateName', 'templateDescription']);
};

export const filterAllTags = (tags, templates) => {
    if (tags?.length) {
        const newRows = templates.filter((row) => {
            return row.tags?.some((tagName) => tags?.some(({ value }) => tagName.tagId === value));
        });

        return newRows;
    }

    return templates;
};

export const filterFavorite = (isFavSelected, templates) => {
    if (!isFavSelected) {
        return templates;
    }
    const newRows = templates.filter((row) => row?.isFavorite);
    return newRows;
};

export const checkBoxClick = (templateId, selected) => {
    const selectedIndex = selected.indexOf(templateId);
    const newSelected = [];
    if (selectedIndex === -1) {
        return newSelected.concat(selected, templateId);
    }
    if (selectedIndex === 0) {
        return newSelected.concat(selected.slice(1));
    }
    if (selectedIndex === selected.length - 1) {
        return newSelected.concat(selected.slice(0, -1));
    }
    if (selectedIndex > 0) {
        return newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    return newSelected;
};

export const isValidEmail = (email) => {
    email = email.trim();
    // eslint-disable-next-line
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
};

export const getGmtOffSetInMinutes = (gmt) => {
    return Number(gmt) * 60;
};
export const isIncludeInvalidChar = (inputtxt) => {
    // < (less than)
    // > (greater than)
    // : (colon - sometimes works, but is actually NTFS Alternate Data Streams)
    // " (double quote)
    // / (forward slash)
    // \ (backslash)
    // | (vertical bar or pipe)
    // ? (question mark)
    // * (asterisk)
    if (inputtxt === undefined || inputtxt?.length <= 0) {
        return false;
    }
    const v =
        inputtxt.includes('<') ||
        inputtxt.includes('>') ||
        inputtxt.includes(':') ||
        inputtxt.includes(`\\`) ||
        inputtxt.includes(`"`) ||
        inputtxt.includes('/') ||
        inputtxt.includes(`|`) ||
        inputtxt.includes('?') ||
        inputtxt.includes(`*`);
    return v;
};

export const getDuplicateFileNameTemplates = (data) => {
    const lookup = data?.reduce((a, e) => {
        /* eslint-disable no-plusplus */
        a[e.fileName] = ++a[e.fileName] || 0;

        return a;
    }, {});
    const duplicates = data?.filter((e) => lookup[e.fileName]);
    return duplicates;
};

export const updateOrgLevelAlias = (templateFields, orgLevelDescription) => {
    const fieldsWithOrgLevelAlias = [];
    templateFields?.forEach((field) => {
        if (field.name === 'Name1' || field.name === 'Name2' || field.name === 'Name3' || field.name === 'Name4') {
            const myLevel = field?.name?.split('Name')?.[1];
            const orgDescription = orgLevelDescription.find(({ level }) => level === Number(myLevel));
            if (orgDescription === undefined) return;
            const orgDes = orgDescription?.description;

            const a = [];
            field?.fieldAttributes?.forEach((attribute) => {
                if (attribute.id === 1) {
                    const newAttribute = { ...attribute, value: attribute?.value?.length <= 0 ? orgDes : attribute?.value };
                    a.push(newAttribute);
                } else {
                    a.push(attribute);
                }
            });

            field = { ...field, fieldAttributes: a, fieldDisplayName: orgDes };
            fieldsWithOrgLevelAlias.push(field);
        } else {
            fieldsWithOrgLevelAlias.push(field);
        }
    });

    return fieldsWithOrgLevelAlias;
};

export const getFilters = (selectedFilters, directoryFieldId) => {
    const filters = [];
    selectedFilters?.forEach((filter) => {
        if (filter.fieldId === directoryFieldId) {
            filter.data?.forEach((item) => {
                let clonedObject = { ...item };
                clonedObject = { ...clonedObject, operator: filter.operator };
                filters.push(clonedObject);
            });
        } else {
            if (filter.filterType && filter.filterType === FilterType.duration) {
                const newValues = [];
                for (let j = 0; j < filter.values?.length; j += 1) {
                    const durationInFormat = filter.values[j]?.value;
                    const durationArr = durationInFormat.split(':');
                    const durationInSeconds = +durationArr[0] * 60 * 60 + +durationArr[1] * 60 + +durationArr[2];
                    newValues.push({ id: filter.values[j]?.id, value: durationInSeconds?.toString() });
                }
                filter = {
                    ...filter,
                    values: newValues
                };
            }
            filters.push(filter);
        }
    });
    return filters;
};

export const getCurrentDateBasedOnLocale = (selectedGmt) => {
    const date = new Date();
    date.setMinutes(date.getMinutes() + selectedGmt * 60);
    const d = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );
    return d;
};
export const isValidFormat = (duration, filterType, dataType) => {
    if (filterType === FilterType.duration && duration?.value?.length > 0) {
        return duration?.value?.match(`^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$`);
    }
    if (dataType === OperatorType.numeric) {
        return false;
    }

    return true;
};

export const getDateUsingOffSet = (date, time, gmtOffsetInMinutes, dateFormat = 'YYYY-MM-DD') => {
    const targetTime = new Date(date?.concat(' ').concat(time));
    const offsetTime = new Date(targetTime.getTime() + gmtOffsetInMinutes * 60 * 1000);
    return moment(new Date(offsetTime)).format(dateFormat);
};

export const hasDuplicates = (arr) => {
    const valuesArray = arr.map((obj) => obj.value);
    for (let i = 0; i < valuesArray.length; i += 1) {
        if (valuesArray.indexOf(valuesArray[i]) !== i) {
            return true;
        }
    }
    return false;
};
export const isNumeric = (n) => {
    return !Number.isNaN(parseFloat(Number(n))) && Number.isFinite(Number(n));
};

export const hasWrongFormat = (dataType, arr) => {
    if (dataType === OperatorType.numeric) {
        const valuesArray = arr.map((obj) => obj.value);
        for (let i = 0; i < valuesArray.length; i += 1) {
            if (!isNumeric(valuesArray[i])) {
                return true;
            }
        }
    }
    return false;
};

export const betweenRangeError = (valuesArr) => {
    const valuesArray = valuesArr.map((obj) => obj.value);
    const filteredArray = valuesArray.filter((str) => str);
    if (filteredArray.length > 1) {
        return hasDuplicates(valuesArr);
    }
    return false;
};

export const toKebabCase = (string) =>
    string
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();

// Function to set the time of a date object to the beginning of the day (00:00:00)
const startOfDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};

// Function to set the time of a date object to the end of the day (23:59:59)
const endOfDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
};

// Function to set the date to the start of the week (Sunday)
const startOfWeek = (date, startDayOfWeek) => {
    const diff = (7 + (date.getDay() - startDayOfWeek)) % 7;
    const startOfWeekDate = new Date(date);
    startOfWeekDate.setDate(startOfWeekDate.getDate() - diff);
    return startOfWeekDate;
};

// Function to set the date to the start of the previous nth week (Sunday)
const startOfPreviousNthWeek = (date, n, startDayOfWeek) => {
    const startOfWeekDate = startOfWeek(date, startDayOfWeek);
    startOfWeekDate.setDate(startOfWeekDate.getDate() - 7 * n);
    return startOfWeekDate;
};

// Function to set the date to the end of the previous week (Saturday)
const endOfPreviousWeek = (date, startDayOfWeek) => {
    const startOfWeekDate = startOfWeek(date, startDayOfWeek);
    const endOfWeekDate = new Date(startOfWeekDate);
    endOfWeekDate.setDate(endOfWeekDate.getDate() - 1);
    endOfWeekDate.setHours(23, 59, 59, 999);
    return endOfWeekDate;
};

const calculateDateRange = (currentDate, lastDuration, includeToday, relativeValue, duration) => {
    let startDateTime;
    let endDateTime;
    if (relativeValue === 'Last') {
        if (duration === 'Hour') {
            startDateTime = new Date(currentDate.getTime() - lastDuration * 3600000); // Subtract hours in milliseconds
            endDateTime = currentDate;
        } else if (duration === 'Day') {
            startDateTime = startOfDay(new Date(currentDate.getTime() - lastDuration * 86400000)); // Subtract days in milliseconds
            endDateTime = includeToday ? currentDate : endOfDay(new Date(currentDate.getTime() - 86400000)); // Subtract one day if not including today
        } else if (duration === 'Week') {
            startDateTime = startOfPreviousNthWeek(currentDate, lastDuration, 0);
            endDateTime = endOfPreviousWeek(currentDate, 0);
        } else if (duration === 'Month') {
            startDateTime = new Date(currentDate.getFullYear(), currentDate.getMonth() - lastDuration, 1);
            endDateTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59, 999);
        } else if (duration === 'Year') {
            startDateTime = new Date(currentDate.getFullYear() - lastDuration, 0, 1);
            endDateTime = new Date(currentDate.getFullYear() - lastDuration, 11, 31, 23, 59, 59);
        }
    } else if (relativeValue === 'Current') {
        if (duration === 'Day') {
            startDateTime = startOfDay(currentDate);
            endDateTime = currentDate;
        } else if (duration === 'Week') {
            startDateTime = startOfWeek(currentDate, 0);
            endDateTime = includeToday ? currentDate : endOfDay(new Date(currentDate.getTime() - 86400000));
        } else if (duration === 'Month') {
            currentDate.setHours(0, 0, 0, 0);
            startDateTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
            endDateTime = includeToday ? currentDate : endOfDay(new Date(currentDate.getTime() - 86400000));
        } else if (duration === 'Year') {
            startDateTime = new Date(currentDate.getFullYear(), 0, 1);
            endDateTime = includeToday ? currentDate : endOfDay(new Date(currentDate.getTime() - 86400000));
        }
    }
    return { startDateTime, endDateTime };
};

const getUtcDateTime = (date, time, useDst, selectedGmt) => {
    const parseTime = moment(time);
    const parseDate = moment(date);
    // Set the time from startTime to startDate
    parseDate.set({
        hour: parseTime.get('hour'),
        minute: parseTime.get('minute'),
        second: parseTime.get('second')
    });

    const utcTime = parseTime
        .subtract(useDst ? getGmtOffSetInMinutes(selectedGmt) + 60 : getGmtOffSetInMinutes(selectedGmt), 'minutes')
        .format('HH:mm:ss');
    // Convert date to UTC considering the GMT offset
    const utcDate = parseDate
        .subtract(useDst ? getGmtOffSetInMinutes(selectedGmt) + 60 : getGmtOffSetInMinutes(selectedGmt), 'minutes')
        .format('YYYY-MM-DD');
    return { utcDate, utcTime };
};

// Relative date range start date
export const getStartDateRange = (lastDuration, includeToday, startTime, useDst, selectedGmt, relativeValue, duration) => {
    const currentDate = new Date();
    const { startDateTime } = calculateDateRange(currentDate, lastDuration, includeToday, relativeValue, duration);
    const { utcDate, utcTime } = getUtcDateTime(startDateTime, startTime, useDst, selectedGmt);
    return getDateUsingOffSet(utcDate, utcTime, useDst ? getGmtOffSetInMinutes(selectedGmt) + 60 : getGmtOffSetInMinutes(selectedGmt));
};

// Relative date range end date
export const getEndDateRange = (lastDuration, includeToday, endTime, useDst, selectedGmt, relativeValue, duration) => {
    const currentDate = new Date();
    const { endDateTime } = calculateDateRange(currentDate, lastDuration, includeToday, relativeValue, duration);
    const { utcDate, utcTime } = getUtcDateTime(endDateTime, endTime, useDst, selectedGmt);
    return getDateUsingOffSet(utcDate, utcTime, useDst ? getGmtOffSetInMinutes(selectedGmt) + 60 : getGmtOffSetInMinutes(selectedGmt));
};

const getBackgroundColor = (level, totalGroups) => {
    const backgroundColors = [
        '#8A99F9', // Background color for level 0
        '#9EAAFA', // Background color for level 1
        '#B1BBFB',
        '#C5CCFC'
    ];
    const filteredColours = backgroundColors.slice(-totalGroups);
    return filteredColours[level] || '#D8DDFD'; // Default if level is 4 or above
};
export const onRowPrepared = (e, totalGroups) => {
    if (e.rowType === 'group') {
        const backgroundColor = getBackgroundColor(e.groupIndex, totalGroups);
        e.rowElement.style.backgroundColor = backgroundColor;
        e.rowElement.style.color = '#5b5e74';
    }
    if (e.rowType === 'header') {
        e.rowElement.style.backgroundColor = '#2A314D';
        e.rowElement.style.color = '#FFFFFF';
    }
    if (e.rowType === 'data') {
        e.rowElement.style.height = '45px';
    }
};
export const onQueueRowPrepared = (e) => {
    if (e.rowType === 'data') {
        e.rowElement.style.fontSize = '15px';
        e.rowElement.style.height = '45px';
    }
};

export const getLocaleGMTOffSetInMinutes = () => {
    let gmt = (Math.round(-(new Date().getTimezoneOffset() / 60) * 100) / 100).toFixed(2);
    gmt = Number(gmt);
    if (moment().isDST()) {
        gmt -= 1;
    }
    return getGmtOffSetInMinutes(gmt);
};
export const dateComparator = (a, b) => {
    const firstDate = moment(a.value);
    const secondDate = moment(b.value);
    return firstDate.isBefore(secondDate) ? -1 : 1;
};
export const weekComparator = (a, b) => {
    const [firstDateStr, ...restFirst] = a.value.split(' ');
    const [secondDateStr, ...restSecond] = b.value.split(' ');
    const firstDate = moment(firstDateStr);
    const secondDate = moment(secondDateStr);
    return dateComparator(firstDate, secondDate);
};
export const quarterComparator = (a, b) => {
    const [firstQuarter, firstYear] = a.value.split(' ');
    const [secondQuarter, secondYear] = b.value.split(' ');

    if (firstYear === secondYear) {
        const idxA = firstQuarter.split('Q')[1];
        const idxb = secondQuarter.split('Q')[1];
        return idxA < idxb ? -1 : 1;
    }
    return firstYear < secondYear ? -1 : 1;
};
export const monthsComparator = (a, b) => {
    const [firstMonth, firstYear] = a.value.split(' ');
    const [secondMonth, secondYear] = b.value.split(' ');

    if (firstYear === secondYear) {
        const idxA = Months.findIndex((m) => firstMonth.includes(m));
        const idxb = Months.findIndex((m) => secondMonth.includes(m));
        return idxA < idxb ? -1 : 1;
    }
    return firstYear < secondYear ? -1 : 1;
};

const getDateGroupSubArr = (columnData, dateGroup, reportConfigDetails) => {
    const { dateFormat, isTwelveHourFormat, timeFormat } = reportConfigDetails;
    const dateGroupSubArr = [];
    dateGroup.forEach((dg) => {
        const c = { ...columnData, allowSorting: false };
        switch (dg) {
            case DateGroup.Quarter: {
                c.selector = (d) => {
                    const date = moment(d.DateTime);
                    return `Q${date.quarter()} ${date.year()}`;
                };
                c.sortingMethod = quarterComparator;
                break;
            }
            case DateGroup.Month: {
                c.selector = (d) => {
                    const date = moment(d.DateTime);
                    return `${date.format('MMMM')} ${date.year()}`;
                };
                c.sortingMethod = monthsComparator;
                break;
            }
            case DateGroup.Week: {
                c.selector = (d) => {
                    const date = moment(d.DateTime);
                    return `${date.startOf('week').format(dateFormat)} - ${date.endOf('week').format(dateFormat)}`;
                };
                c.sortingMethod = weekComparator;
                break;
            }
            case DateGroup.Date: {
                c.selector = (d) => {
                    const date = moment(d.DateTime);
                    return date.format(dateFormat);
                };
                c.sortingMethod = dateComparator;
                break;
            }
            case DateGroup.Hour: {
                c.selector = (d) => {
                    const date = moment(d.DateTime);
                    return date.format(CommonTimeFormat);
                };
                c.customizeText = (cellInfo) => {
                    const date = moment(cellInfo.value, CommonTimeFormat);
                    const dateStr = isTwelveHourFormat ? date.format(`${timeFormat} A`) : date.format(timeFormat);
                    return dateStr;
                };
                break;
            }
            default:
                console.log('Incorrect date group');
                break;
        }
        dateGroupSubArr.push(c);
    });
    return dateGroupSubArr;
};

const getDataField = (fieldConfig) => {
    if (fieldConfig?.formula && fieldConfig?.formula?.type === FormulaType.WeightedAverage) {
        return null;
    }
    return fieldConfig?.fieldName;
};

export const generateSummaryReportFields = (reportDetails) => {
    const { reportSummaryDetails, reportConfigDetails, summaryFieldsConfig } = reportDetails;
    const { summaryDisplayOptions, summaryGroupOptions } = reportSummaryDetails;

    const fields = [];
    const sortOrder = summaryDisplayOptions?.sortType === 'Ascending' ? 'asc' : 'desc';
    const sortField = summaryDisplayOptions?.sortBy;
    const dateGroup = summaryGroupOptions?.dateGroupsToInclude;
    const groupBy = summaryGroupOptions?.groupBy;
    const hideBreakOutWithNoData = summaryDisplayOptions?.breakoutOptions === BreakoutShowHideOptions.HideBreakout;

    summaryFieldsConfig?.forEach((fieldConfig) => {
        const c = {
            caption: fieldConfig?.fieldDisplayName,
            width: 120,
            dataField: isEmpty(fieldConfig?.formula) ? fieldConfig?.fieldName : null,
            area: fieldConfig?.area,
            dataType: fieldConfig?.dataType,
            allowSorting: true,
            summaryType: fieldConfig?.summaryType?.toLowerCase(),
            format: fieldConfig?.format,
            visible: fieldConfig?.isVisible
        };
        if (fieldConfig?.formula && fieldConfig?.formula.type === FormulaType.Percentage) {
            c.format = {
                type: 'percent',
                precision: 2
            };
            c.calculateSummaryValue = (summaryCell) => {
                const totalField = fieldConfig?.formula?.denominatorField;
                const valueField = fieldConfig?.formula?.numeratorField;
                const totalValue = summaryCell.value(totalField);
                const value = summaryCell.value(valueField);
                if (value === 0) return null;
                return totalValue !== 0 && totalValue !== undefined ? value / totalValue : totalValue;
            };
        } else if (fieldConfig?.formula && fieldConfig?.formula.type === FormulaType.WeightedAverage) {
            c.summaryType = 'custom';
            const weightField = fieldConfig?.formula?.weightField;
            const currentField = fieldConfig?.fieldName;
            c.calculateCustomSummary = (options) => {
                if (options.summaryProcess === 'start') {
                    options.totalValue = -1;
                    options.totalSum = 0;
                    options.totalCountSum = 0;
                    options.calculation = 0;
                    options.validData = false;
                }
                if (options.summaryProcess === 'calculate') {
                    if (
                        options.value[currentField] !== -1 &&
                        options.value[currentField] !== null &&
                        options.value[currentField] !== undefined
                    ) {
                        options.calculation = options.value[weightField] * options.value[currentField];
                        options.totalSum += options.calculation;
                        options.totalCountSum += options.value[weightField];
                        options.validData = true;
                    }
                }
                if (options.summaryProcess === 'finalize') {
                    options.totalValue = options.validData ? options.totalSum / options.totalCountSum : -1;
                }
            };
        } else {
            c.calculateSummaryValue = (summaryCell) => {
                const value = summaryCell.value(c.caption);
                if (fieldConfig.fieldName === SummaryFieldNames.ServiceLevel && value === 0) {
                    return value;
                }
                return (value === 0 || value === '') && hideBreakOutWithNoData ? null : value; // Does this check only applies to 0, not to Nan or undefined?
            };
        }

        // Is this if check needs to be validated against Queue only, or against sortField
        if (fieldConfig.fieldName === SummaryFieldNames.Queue) {
            c.sortOrder = sortOrder;
            c.sortBySummaryField = sortField;
            if (sortField === SummaryFieldNames.Queue) {
                c.sortingMethod = (a, b) => {
                    return a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 1;
                };
            }
        }

        if (fieldConfig.fieldName === SummaryFieldNames.DateTime) {
            if (groupBy === GroupBy.QueueAndDateGroups && dateGroup.length > 0) {
                const dateGroupSubArr = getDateGroupSubArr(c, dateGroup, reportConfigDetails);
                fields.push(...dateGroupSubArr);
                return;
            }
            if (groupBy === GroupBy.QueueOnly) {
                const { dateFormat } = reportConfigDetails;
                const startDate = getDateUsingOffSet(
                    reportDetails.reportStartDate,
                    reportDetails.reportStartTime,
                    reportDetails.gmtOffsetInMinutes,
                    dateFormat
                );
                const endDate = getDateUsingOffSet(
                    reportDetails.reportEndDate,
                    reportDetails.reportEndTime,
                    reportDetails.gmtOffsetInMinutes,
                    dateFormat
                );
                c.selector = (d) => {
                    return `${startDate} - ${endDate}`;
                };
            }
        }

        fields.push(c);
    });

    return fields;
};
export const glowAnimation = {
    '@keyframes glow': {
        '0%': {
            textShadow: '0 0 2px red'
        },
        '50%': {
            textShadow: '0 0 10px red'
        },
        '100%': {
            textShadow: '0 0 2px red'
        }
    }
};
