import React, { useState } from 'react';
import { AreaChart, Area, Label, XAxis, YAxis, Legend, Tooltip, ResponsiveContainer } from 'recharts';

// material-ui
import { useTheme } from '@mui/material/styles';

// project imports
import { formatNumbers } from 'utils/format';
import CustomTooltip from './CustomTooltip';
import { getInitialBarProps, getYAxisCaption } from 'utils/graphUtils';
import debounce from 'utils/generic';
import CustomLegend from './CustomLegend';

const BasicAreaChart = ({
    data,
    widgetConfig,
    colors,
    handleDrillDown,
    generateGradients,
    generateTooltip,
    generateGraphics,
    showPointerCursor
}) => {
    const theme = useTheme();
    const themeColors = theme.palette.mode === 'dark' ? colors.dark : colors.light;
    // this state is used to store the hover over points on the graph to correctly drill down to the clicked point.
    // This needs to be done because Area onClick does not provide the clicked data point unlike Bar components
    const [activeHoverPoint, setActiveHoverPoint] = useState(null);
    // debounce the setActiveHoverPoint so that the state doesn't get updated continously on hovering over the chart
    const debounceSetActiveHoverPoint = debounce(setActiveHoverPoint, 300);

    const [areaProps, setAreaProps] = useState(getInitialBarProps(data));

    const handleLegendClick = (entry, event) => {
        event.stopPropagation();
        const selectedProp = entry.dataKey;
        setAreaProps({
            ...areaProps,
            [selectedProp]: !areaProps[entry.dataKey]
        });
    };

    // const generateArea = (graphData, colors, areaProps) => {
    //     const keysArr = Object.keys(graphData[0]).slice(1);
    //     const areaArr = [];
    //     keysArr.forEach((item, index) => {
    //         areaArr.push(
    //             <Area
    //                 type="monotone"
    //                 dataKey={item}
    //                 key={item}
    //                 stroke={colors[item]}
    //                 fillOpacity={1}
    //                 fill={`url(#color${index})`}
    //                 hide={areaProps[item]}
    //             />
    //         );
    //     });
    //     return areaArr;
    // };

    const handleAreaClick = () => {
        const drillDownXAxis = activeHoverPoint && activeHoverPoint[0]?.payload && activeHoverPoint[0]?.payload[widgetConfig.dataKey];
        if (drillDownXAxis) handleDrillDown(drillDownXAxis, widgetConfig);
    };

    // const formatXAxisTick = (tickItem) => tickItem.split('-')[0];

    return (
        <ResponsiveContainer width={widgetConfig.width} height={widgetConfig.height}>
            <AreaChart
                data={data}
                width={500}
                height={400}
                margin={widgetConfig.margin}
                onClick={handleAreaClick}
                cursor={showPointerCursor ? 'pointer' : 'default'}
            >
                <defs>{generateGradients(widgetConfig.chartData, themeColors)}</defs>
                <XAxis dataKey={widgetConfig.dataKey} tickFormatter={widgetConfig.xAxisTickFormatter} tick={widgetConfig.tick} />
                <YAxis
                    axisLine={false}
                    tickFormatter={widgetConfig.yAxisTickFormatter}
                    tick={widgetConfig.tick}
                    width={widgetConfig.yAxisWidth}
                >
                    <Label
                        offset={8}
                        value={widgetConfig.labelValue}
                        angle={-90}
                        position={{ x: '5%', y: '25%' }}
                        style={{ fill: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[700] }}
                        id={`yAxisLabel${widgetConfig?.id}`}
                    />
                </YAxis>
                {generateTooltip(debounceSetActiveHoverPoint, themeColors)}
                <Legend content={<CustomLegend onLegendClick={handleLegendClick} selectedData={areaProps} />} />
                {generateGraphics(areaProps, themeColors)}
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default BasicAreaChart;
